let state = { 
    editData: {}
},
    getters = {
        editData: state => state.editData
    },

    mutations = {
        setEditData(state, paylaod) {
            state.editData = paylaod
        }
    },

    actions = {
        // testActions ({ commit }, num) {
        //     setTimeout(() => {
        //         commit('setTotal', num)
        //         console.log(num)
        //     }, 2000)
        // }
    };
    
export default {
    state,
    getters,
    mutations,
    actions
}
