
let state = {},
getters={},
mutations={},
actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}