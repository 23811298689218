import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'

const routes = [{
		path: '/',
		name: 'App',
		component: App
	},
	{
		path: '/appToQr/:json',
		name: 'AppToQr',
		component: () => import('@/views/AppToQr.vue')
	},
	{
		path: '/enterpriseJump/:id',
		name: 'EnterpriseJump',
		meta: { title: '企业监管' },
		component: () => import('@/views/EnterpriseJump.vue')
	},
	{
		path: '/user/:id',
		name: 'User',
		meta: { title: '从业人员列表' },
		component: () => import('@/views/User.vue')
	},
	{
		path: '/equipment/:id',
		name: 'Equipment',
		meta: { title: '特种设备列表' },
		component: () => import('@/views/Equipment.vue')
	},
	{
		path: '/equipmentD/:id',
		name: 'EquipmentD',
		meta: { title: '设备监管' },
		component: () => import('@/views/EquipmentD.vue')
	},
	{
		path: '/enterpriseNo',
		name: 'EnterpriseNo',
		component: () => import('@/views/EnterpriseNo.vue')
	},
	{
		path: '/enterpriseEditJump/:id',
		name: 'EnterpriseEditJump',
		meta: { title: '企业编辑上报' },
		component: () => import('@/views/edit/EnterpriseEditJump.vue')
	},
	{
		path: '/enterpriseChoice/:id',
		name: 'EnterpriseChoice',
		meta: { title: '企业新增上报' },
		component: () => import('@/views/EnterpriseChoice.vue')
	},
	{
		path: '/enterpriseAddCy/:type',
		name: 'EnterpriseAddCy',
		meta: { title: '餐饮信息上报' },
		component: () => import('@/views/add/EnterpriseAddCy.vue')
	},
	{
		path: '/enterpriseAdd/:id',
		name: 'EnterpriseAdd',
		meta: { title: '从业人员信息编辑' },
		component: () => import('@/views/add/EnterpriseAdd.vue')
	},
	{
		path: '/personnelAdd/',
		name: 'personnelAdd',
		meta: { title: '新增从业人员' },
		component: () => import('@/views/add/PersonnelAdd.vue')
	},
	{
		path: '/personnelEidt/:userId',
		name: 'personnelEdit',
		meta: { title: '编辑从业人员' },
		component: () => import('@/views/add/PersonnelAdd.vue')
	},
	{
		path: '/equipmentAdd/',
		name: 'EquipmentAdd',
		meta: { title: '新增特种设备' },
		component: () => import('@/views/add/EquipmentAdd.vue')
	},
	{
		path: '/equipmentEdit/:facilityId',
		name: 'EquipmentEdit',
		meta: { title: '编辑特种设备'},
		component: () => import('@/views/add/EquipmentAdd.vue')
	},
	{
		path: '/enterpriseAddXs/:type',
		name: 'EnterpriseAddXs',
		meta: { title: '食品销售信息上报' },
		component: () => import('@/views/add/EnterpriseAddXs.vue')
	},
	{
		path: '/enterpriseAddSc/:type',
		name: 'EnterpriseAddSc',
		meta: { title: '食品生产信息上报' },
		component: () => import('@/views/add/EnterpriseAddSc.vue')
	},
	{
		path: '/enterpriseAddYd/:type',
		name: 'EnterpriseAddYd',
		meta: { title: '单体药店信息上报' },
		component: () => import('@/views/add/EnterpriseAddYd.vue')
	},
	{
		path: '/enterpriseAddYdL/:type',
		name: 'EnterpriseAddYdL',
		meta: { title: '连锁药店信息上报' },
		component: () => import('@/views/add/EnterpriseAddYdL.vue')
	},
	{
		path: '/enterpriseAddTs/:type',
		name: 'EnterpriseAddTs',
		meta: { title: '特设信息上报' },
		component: () => import('@/views/add/EnterpriseAddTs.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	let token = sessionStorage.getItem('userToken')
	// if(!token && to.path.indexOf('enterpriseJump')!=-1) {
	// 	let url = to.path
	// 	let code = url.substr(url.lastIndexOf('/'))
	// 	router.push({name: 'EnterpriseJump', params: {id: code} })
	// }else{
		/* 路由发生变化修改页面title */
		if (to.meta.title) {
			document.title = to.meta.title
		}
		next()
	// }
})

export default router
