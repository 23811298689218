let state = {
		// username: 'admin',
		// total: 0,
		// accessToken: 'admin',
		// avatar: '随意设置',
		// rolename: '随意设置',
		// userList: [{ name: 'admin', age: 29 }]
		editData: {}
	},
	getters = {
		
		editData: state => state.editData
	},
	mutations = {
		
		setEditData(state, paylaod) {
			state.editData = paylaod
		}
	},
	actions = {
		// testActions ({ commit }, num) {
		//     setTimeout(() => {
		//         commit('setTotal', num)
		//         console.log(num)
		//     }, 2000)
		// }
	};
export default {
	state,
	getters,
	mutations,
	actions
}
