import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import http from '@/utils/http.js'
import './style.css'

import {
	Notify,
	Toast,
	Search,
	Popup,
	Popover,
	PullRefresh,
	List,
	Button,
	ImagePreview,
	Form,
	Field,
	Icon,
	RadioGroup,
	Radio,
	Cell,
	CellGroup,
	Col,
	Row,
	DatetimePicker,
	Picker,
	Cascader,
	Uploader,
	Checkbox,
	CheckboxGroup,
	Tab,
	Tabs,
	Dialog,
	Grid, GridItem
} from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible/flexible.js'

const app = createApp(App);
app.config.globalProperties.$http = http;

app.use(Notify)
	.use(Toast)
	.use(Search)
	.use(Popup)
	.use(Popover)
	.use(PullRefresh)
	.use(List)
	.use(ImagePreview)
	.use(Form)
	.use(Field)
	.use(Icon)
	.use(RadioGroup)
	.use(Radio)
	.use(Cell)
	.use(CellGroup)
	.use(Button)
	.use(Col)
	.use(Row)
	.use(DatetimePicker)
	.use(Picker)
	.use(Cascader)
	.use(Uploader)
	.use(Checkbox)
	.use(CheckboxGroup)
	.use(Tab)
	.use(Tabs)
	.use(Grid)
	.use(GridItem)
	.use(Dialog)

app.use(store)
	.use(router)
	.mount('#app')
