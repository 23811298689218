import axios from 'axios'
import qs from 'qs'
import merge from 'lodash/merge'

const http = axios.create({
	timeout: 1000 * 30,
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

const BASE_URL_prod = 'https://aa.jscofly.cn'
// const BASE_URL_prod = 'http://192.168.0.253:9090'
const BASE_URL_dev = 'http://192.168.0.210:9090'

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
	return response
}, error => {
	return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
	// return  'http://192.168.0.210:9090'+actionName
	// return  'https://aa.jscofly.cn'+actionName
	return process.env.NODE_ENV!=='development'? (BASE_URL_prod+actionName) : (BASE_URL_dev + actionName)
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
	var defaults = { 't': new Date().getTime() }
	return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
	var defaults = { 't': new Date().getTime() }
	data = openDefultdata ? merge(defaults, data) : data
	return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
